import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from '@mui/material'
import { InputSearch } from '../../../components/Input/InputSearch'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useBalance } from '../../../hooks/useBalance'
import clsx from 'clsx'
import { BalancesFilter } from '../../../components/Balances/BalancesFilter'
import { Loader } from '../../../components/Loader/Loader'
import { EmptyMessage } from '../../../components/EmptyMessage/EmptyMessage'
import styles from '../../../styles/Balances.module.css'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

export const SupplierAmountContent: React.FC = () => {
  const { isMobile } = useMediaQueryContext()
  const {
    setSupplierAmountBalances,
    setSupplierAmountFilters,
    getSupplierAmountBalancesThunkCreator,
    getManufacturersThunkCreator
  } = useActions()
  const { supplierAmountBalances, isLoaded, supplierAmountFilters } = useTypeSelector((state) => state.balances)
  const { manufacturers } = useTypeSelector((state) => state.manufacturers)
  const {
    sortedWarehouses,
    dragStartHandler,
    dragLeaveHandler,
    dragEndHandler,
    dragOverHandler,
    dragDropHandler,
    CELL_WIDTH_STYLE,
    contentScrollRef,
    fixedScrollRef,
    headerScrollRef,
    syncScroll
  } = useBalance()

  const [query, setQuery] = useState<string>('')
  const [tableWidth, setTableWidth] = useState<number>(0)
  const tableRef = useRef<HTMLTableElement>(null)

  const debouncedSearch = useCallback(
    debounce((search: string) => setSupplierAmountFilters({ search }), 800),
    []
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setQuery(value)
    debouncedSearch(value)
  }

  useEffect(() => {
    if (tableRef.current) setTableWidth(tableRef.current.offsetWidth)
  }, [supplierAmountBalances, isMobile])

  useEffect(() => {
    setSupplierAmountBalances([])
    !manufacturers.length && getManufacturersThunkCreator()
  }, [])

  useEffect(() => {
    getSupplierAmountBalancesThunkCreator(supplierAmountFilters)
  }, [supplierAmountFilters])

  return (
    <div className={styles.content}>
      <InputSearch onChange={handleSearch} value={query} placeholder="Введите название или код товара" />
      <BalancesFilter type="supplier_amount" />
      <div className={clsx(styles.tableContainer)}>
        {!!sortedWarehouses.length && !!supplierAmountBalances.length ? (
          <>
            <div className={styles.tableHeaderContainer} ref={headerScrollRef} onScroll={syncScroll}>
              <table className={styles.tableHeader}>
                <thead className={styles.thead}>
                  <tr className={styles.row}>
                    <td className={clsx(styles.cell, styles.productCell, styles.theadFirstCellSticky)}>Товар</td>
                    <td
                      className={clsx(styles.cell, styles.balanceCell, styles.amount, styles.theadSecondCellSticky)}
                      style={CELL_WIDTH_STYLE}
                    >
                      Итог
                    </td>
                    {sortedWarehouses.map((item) => (
                      <td
                        key={item.id}
                        className={clsx(styles.cell, styles.cursorMove, styles.warehouseCell, styles.amount)}
                        style={CELL_WIDTH_STYLE}
                        draggable={true}
                        onDragStart={(e) => dragStartHandler(e, item)}
                        onDragLeave={(e) => dragLeaveHandler(e)}
                        onDragEnd={(e) => dragEndHandler(e, item)}
                        onDragOver={(e) => dragOverHandler(e)}
                        onDrop={(e) => dragDropHandler(e, item)}
                      >
                        {item.name}
                      </td>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            <div className={styles.tableBodyContainer} ref={contentScrollRef} onScroll={syncScroll}>
              <table className={styles.tableBody} ref={tableRef}>
                <tbody className={styles.emptyBody}></tbody>
                <tbody className={styles.tbody}>
                  <tr className={styles.row}>
                    <td
                      className={clsx(styles.cell, styles.balanceCell, styles.amount, styles.tbodyFirstCellSticky)}
                      style={CELL_WIDTH_STYLE}
                    >
                      Итог
                    </td>
                    <td
                      className={clsx(styles.cell, styles.balanceCell, styles.amount, styles.tbodySecondCellSticky)}
                      style={CELL_WIDTH_STYLE}
                    >
                      {supplierAmountBalances.reduce((acc, item) => acc + item.totalAmount, 0)} ₽
                    </td>
                    {sortedWarehouses.map(({ id }) => (
                      <td key={id} className={clsx(styles.cell, styles.balanceCell, styles.amount)} style={CELL_WIDTH_STYLE}>
                        {supplierAmountBalances.reduce(
                          (acc, item) => acc + (item.warehouses.find((w) => w.warehouse_id === id)?.amount || 0),
                          0
                        )}{' '}
                        ₽
                      </td>
                    ))}
                  </tr>
                  {supplierAmountBalances.map((item) => (
                    <tr key={item.id} className={styles.row}>
                      <td className={clsx(styles.cell, styles.productCell, styles.tbodyFirstCellSticky)}>
                        <ProductInfo product={item} clamp={false} />
                      </td>
                      <td
                        className={clsx(styles.cell, styles.balanceCell, styles.amount, styles.tbodySecondCellSticky)}
                        style={CELL_WIDTH_STYLE}
                      >
                        {item.totalAmount} ₽
                      </td>
                      {sortedWarehouses.map(({ id }) => (
                        <td key={id} className={clsx(styles.cell, styles.warehouseCell, styles.amount)} style={CELL_WIDTH_STYLE}>
                          {item.warehouses.find((warehouse) => warehouse.warehouse_id === id)?.amount || 0} ₽
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : isLoaded ? (
          <Loader className={styles.loader} />
        ) : (
          <EmptyMessage className={styles.emptyData}>Данные об поставках отсутствуют</EmptyMessage>
        )}
      </div>
      {!!sortedWarehouses.length && !!supplierAmountBalances.length && (
        <div className={styles.fixedScrollbarX} ref={fixedScrollRef} onScroll={syncScroll}>
          <div className={styles.scrollbarInnerX} style={{ width: tableWidth }}></div>
        </div>
      )}
    </div>
  )
}
