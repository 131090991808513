import { BalancesState, BalancesAction, BalancesActionsTypes } from '../../types/balancesTypes'

const initialState: BalancesState = {
  balances: [],
  amountBalances: [],
  supplierBalances: [],
  supplierAmountBalances: [],
  warehouses: [],
  isLoaded: false,
  filters: { date: null, manufacturers: [], search: null, notNull: false, mainWarehouses: false },
  amountFilters: { date: null, manufacturers: [], search: null, notNull: false, mainWarehouses: false },
  supplierFilters: { manufacturers: [], search: null, notNull: false },
  supplierAmountFilters: { manufacturers: [], search: null, notNull: false }
}

export const balancesReducer = (state = initialState, action: BalancesAction): BalancesState => {
  switch (action.type) {
    case BalancesActionsTypes.GET_BALANCES:
      return {
        ...state,
        balances: action.payload.balances,
        warehouses: action.payload.warehouses,
        isLoaded: false
      }
    case BalancesActionsTypes.GET_AMOUNT_BALANCES:
      return {
        ...state,
        amountBalances: action.payload.amountBalances,
        warehouses: action.payload.warehouses,
        isLoaded: false
      }
    case BalancesActionsTypes.GET_SUPPLIER_BALANCES:
      return {
        ...state,
        supplierBalances: action.payload.supplierBalances,
        warehouses: action.payload.warehouses,
        isLoaded: false
      }
    case BalancesActionsTypes.GET_SUPPLIER_AMOUNT_BALANCES:
      return {
        ...state,
        supplierAmountBalances: action.payload.supplierAmountBalances,
        warehouses: action.payload.warehouses,
        isLoaded: false
      }
    case BalancesActionsTypes.SET_BALANCES_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }
    case BalancesActionsTypes.SET_BALANCES:
      return {
        ...state,
        balances: action.payload
      }
    case BalancesActionsTypes.SET_AMOUNT_BALANCES:
      return {
        ...state,
        amountBalances: action.payload
      }
    case BalancesActionsTypes.SET_SUPPLIER_BALANCES:
      return {
        ...state,
        supplierBalances: action.payload
      }
    case BalancesActionsTypes.SET_SUPPLIER_AMOUNT_BALANCES:
      return {
        ...state,
        supplierAmountBalances: action.payload
      }
    case BalancesActionsTypes.SET_BALANCES_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload
      }
    case BalancesActionsTypes.SET_BALANCE_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      }
    case BalancesActionsTypes.SET_AMOUNT_FILTERS:
      return {
        ...state,
        amountFilters: { ...state.amountFilters, ...action.payload }
      }
    case BalancesActionsTypes.SET_SUPPLIER_FILTERS:
      return {
        ...state,
        supplierFilters: { ...state.supplierFilters, ...action.payload }
      }
    case BalancesActionsTypes.SET_SUPPLIER_AMOUNT_FILTERS:
      return {
        ...state,
        supplierAmountFilters: { ...state.supplierAmountFilters, ...action.payload }
      }
    default:
      return state
  }
}
