import React, { ChangeEvent, useState } from 'react'
import { Filter } from '../Filter/Filter'
import { FilterSection } from '../Filter/FilterSection'
import { Box } from '@mui/material'
import DatePicker from 'react-datepicker'
import { DateHeader } from '../DateInput/DateHeader'
import { BalanceFilters, SupplierFilters } from '../../types/balancesTypes'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { Manufacturer } from '../../types/manufacturersTypes'
import { LabelSelected } from '../Label/LabelSelected'
import { formatDate } from '../../infrasturcture/utils/formatDate'
import { Checkbox } from '../Checkbox/Checkbox'
import { FilterList } from '../Filter/FilterList'
import ruLocale from 'date-fns/locale/ru'

const initialBalancesFilters: BalanceFilters = {
  date: null,
  manufacturers: [],
  search: null,
  notNull: false,
  mainWarehouses: false
}

const initialSupplierFilters: SupplierFilters = {
  manufacturers: [],
  search: null,
  notNull: false
}

type BalancesFilterType = 'balances' | 'supplier' | 'amount_balances' | 'supplier_amount'

type BalancesStateFilterType = BalanceFilters | SupplierFilters

type BalancesState = Record<
  BalancesFilterType,
  {
    stateFilters: BalancesStateFilterType
    setStateFilters: (payload: Partial<BalancesStateFilterType>) => void
    initialFilters: BalancesStateFilterType
  }
>

interface BalancesFilterProps {
  type: BalancesFilterType
}

export const BalancesFilter: React.FC<BalancesFilterProps> = ({ type }) => {
  const { setBalanceFilters, setSupplierFilters, setAmountBalanceFilters, setSupplierAmountFilters } = useActions()
  const {
    filters: balancesFilters,
    supplierFilters,
    amountFilters,
    supplierAmountFilters
  } = useTypeSelector((state) => state.balances)
  const manufacturers = useTypeSelector((state) => state.manufacturers.manufacturers)

  const state: BalancesState = {
    balances: { stateFilters: balancesFilters, setStateFilters: setBalanceFilters, initialFilters: initialBalancesFilters },
    supplier: { stateFilters: supplierFilters, setStateFilters: setSupplierFilters, initialFilters: initialSupplierFilters },
    amount_balances: {
      stateFilters: amountFilters,
      setStateFilters: setAmountBalanceFilters,
      initialFilters: initialBalancesFilters
    },
    supplier_amount: {
      stateFilters: supplierAmountFilters,
      setStateFilters: setSupplierAmountFilters,
      initialFilters: initialSupplierFilters
    }
  }

  const { stateFilters, setStateFilters, initialFilters } = state[type]

  const [filters, setFilters] = useState<BalancesStateFilterType>(initialFilters)

  const isBalances = type === 'balances' || type === 'amount_balances'

  const handleChange = (value: Manufacturer) => (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setFilters((prev) => ({
      ...prev,
      manufacturers: checked ? [...prev.manufacturers, value] : prev.manufacturers.filter((v) => v.id !== value.id)
    }))
  }

  const handleExtraChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, [e.target.value]: e.target.checked }))
  }

  const handleDelete = (key: 'date' | 'manufacturers' | 'notNull' | 'mainWarehouses', value: string) => {
    if (key === 'date') {
      setStateFilters({ date: null })
      return setFilters((prev) => ({ ...prev, date: null }))
    }
    if (key === 'notNull' || key === 'mainWarehouses') {
      setStateFilters({ [key]: false })
      return setFilters((prev) => ({ ...prev, [key]: false }))
    }
    const manufacturers = filters.manufacturers.filter((v) => v.id !== value)
    setStateFilters({ manufacturers })
    setFilters((prev) => ({ ...prev, manufacturers }))
  }

  const handleToggle = () => setFilters(stateFilters)

  const handleApply = () => {
    filters && setStateFilters(filters)
  }

  const handleReset = () => {
    setStateFilters(initialFilters)
    setFilters(initialFilters)
  }

  return (
    <Filter
      onApply={handleApply}
      onReset={handleReset}
      onToggle={handleToggle}
      selectedComponent={
        <>
          {stateFilters.manufacturers.map((item, i) => (
            <LabelSelected key={i} text={item.name} onClick={() => handleDelete('manufacturers', item.id)} />
          ))}
          {'date' in stateFilters && stateFilters.date && (
            <LabelSelected text={formatDate(stateFilters.date)} onClick={() => handleDelete('date', '')} />
          )}
          {'notNull' in stateFilters && stateFilters.notNull && (
            <LabelSelected text="Ненулевые остатки" onClick={() => handleDelete('notNull', '')} />
          )}
          {'mainWarehouses' in stateFilters && (stateFilters as BalanceFilters).mainWarehouses && (
            <LabelSelected text="Основные склады" onClick={() => handleDelete('mainWarehouses', '')} />
          )}
        </>
      }
    >
      {isBalances && 'date' in filters && (
        <FilterSection title="Дата" isCheckboxes={false}>
          <Box sx={{ mt: '20px', maxHeight: 387 }}>
            <DatePicker
              calendarClassName="small"
              renderCustomHeader={DateHeader}
              selected={filters.date}
              startDate={filters.date}
              maxDate={new Date()}
              inline
              onChange={(date: any) => setFilters((prev) => ({ ...prev, date }))}
              locale={ruLocale}
            />
          </Box>
        </FilterSection>
      )}
      {!!manufacturers.length && (
        <FilterSection title="Производители">
          {manufacturers.slice(0, 5)?.map((manufacturer, i) => (
            <Checkbox
              onChange={handleChange(manufacturer)}
              key={i}
              label={manufacturer.name}
              value={manufacturer.id}
              checked={!!filters.manufacturers.find((m) => m.id === manufacturer.id)}
              labelStyle={{ marginBottom: -4 }}
            />
          ))}
          {manufacturers.length > 5 && (
            <FilterList
              values={manufacturers}
              searchKey="name"
              render={(manufacturer) => (
                <Checkbox
                  onChange={handleChange(manufacturer)}
                  label={manufacturer.name}
                  value={manufacturer.id}
                  checked={!!filters.manufacturers.find((m) => m.id === manufacturer.id)}
                  labelStyle={{ marginBottom: -4 }}
                />
              )}
            />
          )}
        </FilterSection>
      )}
      <FilterSection title="Дополнительно">
        <Checkbox
          onChange={handleExtraChange}
          key="notNull"
          label="Ненулевые остатки"
          value="notNull"
          checked={filters.notNull}
          labelStyle={{ marginBottom: -4 }}
        />
        {isBalances && 'mainWarehouses' in filters && (
          <Checkbox
            onChange={handleExtraChange}
            key="mainWarehouses"
            label="Основные склады"
            value="mainWarehouses"
            checked={filters.mainWarehouses}
            labelStyle={{ marginBottom: -4 }}
          />
        )}
      </FilterSection>
    </Filter>
  )
}
