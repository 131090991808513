import React from 'react'
import { Product } from '../../types/productsTypes'
import styles from './ProductInfo.module.css'
import { Image } from '../Image/Image'
import { CustomTooltip } from '../MuiComponents/CustomTooltip/CustomTooltip'
import { OperationProduct, ProductInOperation } from '../../types/operationsTypes'
import { OrderPostingProduct, ProductInOrder } from '../../types/ordersTypes'
import clsx from 'clsx'
import { ProductInBooking } from '../../types/bookingsTypes'
import { AmountBalance, Balance } from '../../types/balancesTypes'

interface ProductInfoProps {
  product:
    | Product
    | ProductInOperation
    | ProductInBooking
    | ProductInOrder
    | OperationProduct
    | OrderPostingProduct
    | Balance
    | AmountBalance
  isShowTooltip?: boolean
  isOption?: boolean
  clamp?: boolean
}

export const ProductInfo: React.FC<ProductInfoProps> = ({ product, isShowTooltip = true, isOption = false, clamp = true }) => {
  const isRenderTooltip = isShowTooltip && 'custom' in product && 'description_custom' in product && product.custom

  return (
    <div className={clsx(styles.container, { [styles.option]: isOption })}>
      <div className={styles.photo}>
        {product.photo && (
          <Image
            className={styles.photoImage}
            threshold={500}
            alt={product.name}
            src={`${process.env.REACT_APP_API_URL}/static/images/${product.photo}`}
          />
        )}
      </div>
      <div className={clsx(styles.text, { [styles.tooltip]: isRenderTooltip })}>
        <span className={clsx(styles.name, { [styles.clamp]: clamp })}>{product.name}</span>
        <span className={styles.code}>{product.sku}</span>
      </div>
      {isRenderTooltip && <CustomTooltip className={styles.custom} label="Заказной" text={product.description_custom} />}
    </div>
  )
}
