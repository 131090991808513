import React from 'react'
import styles from '../../styles/Balances.module.css'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { MobileHeader } from '../../components/MobileHeader/MobileHeader'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Typography } from '@mui/material'
import { AmountContent } from './ui/AmountContent'

export const Amount: React.FC = () => {
  const { isMobile } = useMediaQueryContext()

  return (
    <div className={styles.container}>
      {isMobile && <MobileHeader />}
      <Breadcrumbs />
      <StyledHeader sx={{ mb: { xs: '32px', md: '40px' } }}>
        <Typography variant="h1">Денежные остатки</Typography>
      </StyledHeader>
      <AmountContent />
    </div>
  )
}
