import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import BalancesApi from '../../api/BalancesApi'
import {
  AmountBalance,
  Balance,
  BalanceFilters,
  BalancesAction,
  BalancesActionsTypes,
  SetAmountBalanceFiltersAction,
  SetAmountBalancesAction,
  SetBalanceFiltersAction,
  SetBalancesAction,
  SetBalancesLoaded,
  SetBalancesWarehousesAction,
  SetSupplierAmountBalancesAction,
  SetSupplierAmountFiltersAction,
  SetSupplierBalancesAction,
  SetSupplierFiltersAction,
  SupplierFilters
} from '../../types/balancesTypes'
import { SetAuthErrorAction } from '../../types/userTypes'
import { setAuthError } from './userActions'
import { Warehouse } from '../../types/warehousesTypes'

type ThunkType = ThunkAction<void, RootState, unknown, BalancesAction | SetAuthErrorAction>

const setBalancesLoaded = (payload: boolean): SetBalancesLoaded => ({
  type: BalancesActionsTypes.SET_BALANCES_LOADED,
  payload
})

export const setBalances = (payload: Balance[]): SetBalancesAction => ({
  type: BalancesActionsTypes.SET_BALANCES,
  payload
})

export const setAmountBalances = (payload: AmountBalance[]): SetAmountBalancesAction => ({
  type: BalancesActionsTypes.SET_AMOUNT_BALANCES,
  payload
})

export const setSupplierBalances = (payload: Balance[]): SetSupplierBalancesAction => ({
  type: BalancesActionsTypes.SET_SUPPLIER_BALANCES,
  payload
})

export const setSupplierAmountBalances = (payload: AmountBalance[]): SetSupplierAmountBalancesAction => ({
  type: BalancesActionsTypes.SET_SUPPLIER_AMOUNT_BALANCES,
  payload
})

export const setBalancesWarehouses = (payload: Warehouse[]): SetBalancesWarehousesAction => ({
  type: BalancesActionsTypes.SET_BALANCES_WAREHOUSES,
  payload
})

export const setBalanceFilters = (payload: Partial<BalanceFilters>): SetBalanceFiltersAction => ({
  type: BalancesActionsTypes.SET_BALANCE_FILTERS,
  payload
})

export const setAmountBalanceFilters = (payload: Partial<BalanceFilters>): SetAmountBalanceFiltersAction => ({
  type: BalancesActionsTypes.SET_AMOUNT_FILTERS,
  payload
})

export const setSupplierFilters = (payload: Partial<SupplierFilters>): SetSupplierFiltersAction => ({
  type: BalancesActionsTypes.SET_SUPPLIER_FILTERS,
  payload
})

export const setSupplierAmountFilters = (payload: Partial<SupplierFilters>): SetSupplierAmountFiltersAction => ({
  type: BalancesActionsTypes.SET_SUPPLIER_AMOUNT_FILTERS,
  payload
})

export const getBalancesThunkCreator = (filters: BalanceFilters): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBalancesLoaded(true))
      const data = await BalancesApi.getBalances(filters)
      dispatch({ type: BalancesActionsTypes.GET_BALANCES, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getAmountBalancesThunkCreator = (filters: BalanceFilters): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBalancesLoaded(true))
      const data = await BalancesApi.getAmountBalances(filters)
      dispatch({ type: BalancesActionsTypes.GET_AMOUNT_BALANCES, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getSupplierBalancesThunkCreator = (filters: SupplierFilters): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBalancesLoaded(true))
      const data = await BalancesApi.getSupplierBalances(filters)
      dispatch({
        type: BalancesActionsTypes.GET_SUPPLIER_BALANCES,
        payload: data
      })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getSupplierAmountBalancesThunkCreator = (filters: SupplierFilters): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setBalancesLoaded(true))
      const data = await BalancesApi.getSupplierAmountBalances(filters)
      dispatch({
        type: BalancesActionsTypes.GET_SUPPLIER_AMOUNT_BALANCES,
        payload: data
      })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}
